$(function () {
    docSlider.init({
        pager: false,
        beforeChange: () => {
            $('#mainMenu').find('li.active').removeClass('active');
        },
        afterChange: (index, page) => {
            const id = $(page).attr('id');

            history.pushState(null, document.title, window.location.pathname + (index > 0 ? '#' + id : ''));

            $('#mainMenu').find('a[href*="#' + id + '"]').parent('li').addClass('active');
        },
    });
});
